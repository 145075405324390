import React from 'react';
import restUtils from '../../../utils/restUtils';
import { Loader, Responsive, Confirm } from 'semantic-ui-react';
import history from '../../../history';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import utilities, { shouldHideSUGG_RETAILForPreference, shouldHideCostForPreference } from '../../../utils/utilities';
import BreadcrumbComp from '../../../components/BreadcrumbComp';
import renderImages from '../../common/RenderImages';
import { MaskPrice } from '../../common/MaskPrice';
import {  PROMO_ITEM_DETAILS,acceptDeclineurl,updatePromodetails} from '../../../urlConstants';
import ExcelExport from '../../common/ExcelExport';
import InternalServerError from '../../ErrorPages/500_error_page';
import './styles.css';
import { MEMBER_VIEW } from '../../../components/NavBar/menuConstants';
import { getTableRow, getSelect, getTable } from '../../common/ComponentUtilities';
import { getSortByDirection, showItemCount, getPagination } from '../../common/CustomComponents';
import { imagePromo,imageColor,decline,accept,disabled } from '../PromoShip/promoconstants';
import { Link } from 'react-router-dom';

class PromoItemDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemDetails: [],
      loading: false,
      serverError: false,
      img_txt_to_display: utilities.getShowImg(props.getprefer),
      orderNum:this.props?.match?.params?.orderNum,
      orderDetails:this.props?.location?.state ? this.props?.location?.state?.orderDetails : null,
      confirmCancel : false,
      disableUpdateOrderbtn : false,
      isNoPromoships:false,
      promoNumber:[],
      promoName:'',
      promoOverRide:[],
      removeItemnbr:[],
      promoShipItemListUpdate:[],
      totalPromo :'',
      exactPromocost:'',
      itemList:{},
      firstItem: 0,
      lastItem: 0,
      currentPage: 0,
      totalPages: 0,
      totalItems: 0
    }
    this.promoFlag=false;
    this.pathname = '';
    this.page = 1;
    this.pageSize = utilities.getResultperPage(props.getprefer);
    this.sortParam = 'itemNum';
    this.sortDirection = 'asc';
    this.scrollPosition = 0;
    this.historyLength=1;
  }

  componentDidMount() {
    let queryParam = window.location.href.split('?');
    this.pathname = this.props?.location?.pathname;
    if(queryParam[1]) {
      this.getUrlFilters(queryParam);
    }
    else{
      this.getItemDetails();
    }
  }

  roundNull = (data, field) => {
    const getPrefer = this.props.getprefer &&
    this.props.getprefer.customerView
    ? this.props.getprefer.customerView
    : 'c1';
    if(data){
      return <MaskPrice
        field = {field}
        getprefer={getPrefer}
        viewType={this.props.viewType}
        fieldVal={Number(data).toFixed(2)}
      />
    } else return "-"; 
  }

  getUrlFilters = (queryParam) => {
    let getParams = queryParam[1].split('&');
    this.page = getParams[0].split('=')[1];
    this.pageSize =  getParams[1].split('=')[1];
    this.sortParam = queryParam[1].includes('&SortParam=') ? queryParam[1].split('SortParam=')[1].split('&')[0] : '';
    this.sortDirection = queryParam[1].includes('SortType=asc') ? 'asc' : 
                          queryParam[1].includes('SortType=desc') ? 'desc' : '';    
    this.scrollPosition = queryParam[1].split('scrollPosition=')[1].split('&')[0];
    this.getItemDetails();
  }


  showHideImages = evt => {
    let img_txt_to_display =
      evt.target.innerHTML === 'Show Images' ? 'Hide Images' : 'Show Images';
    this.setState({ img_txt_to_display: img_txt_to_display });
  };

  changeSortParam = e => {
    this.sortParam = e.currentTarget.value;
    this.getItemDetails();
  };

  changeSortDirection = e => {
    this.sortDirection = e.currentTarget.value;
    this.getItemDetails();
  };


  flagPromo=(itemDetails)=>{
    itemDetails=itemDetails.map((qtyValid,index)=>{
      let promoPackSplit=itemDetails[index].pack;
      let qty=qtyValid.qty;
      let itemNbr = itemDetails[index].itemNbr
      if (
       !this.validateProductQuantity(promoPackSplit,qty, itemNbr)
     ) {
      this.promoFlag=true;
      this.setState({loading:false});
     }
    });
  }


  acceptCancelOrder=(e,orderProcess)=>{
    this.setState({loading:true});
    let{orderDetails,itemDetails} = this.state;
    this.promoFlag=false;
    this.flagPromo(itemDetails);
    let requestPayLoad= {
      "storeID" : this.props.storeId,
      "orderNbr":orderDetails.orderNbr,
      "action" : orderProcess,
      "molUserName" : this.props.userName
  }
  !this.promoFlag && restUtils
      .postData(acceptDeclineurl, requestPayLoad)
      .then(response => {        
        const{data}=response;
        if(data.statusflag){
          orderProcess === accept ? utilities.showToast('Accepted successfully')
          : utilities.showToast('Declined successfully');
          this.setState({ 
            loading: false, 
            serverError: false });
            return;
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false, serverError: true });
      });
  }

  validateProductQuantity = (promoPackSplit,qty,itemNbr)=> {
    let toastError = false;
    let toastMessage = '';
    const packType = promoPackSplit.substring(0,1).toLowerCase()
    const pack = parseInt(promoPackSplit.substring(1,))
    if( packType=='f' && qty % pack ){
      toastMessage = 'Quantity should be multiple of ' + pack + ' for item ' + itemNbr;
      toastError = true;
    }else if (qty === '') {
      toastMessage = 'Please enter a valid quantity';
      toastError = true;
    }
    if (toastError) {
      utilities.showToast(toastMessage,toastError)
    }
    return !toastError;
  };

  setScrollPosition = () => {
    this.scrollPosition = window.pageYOffset;
    this.setBackButtonPath();
  }

  setBackButtonPath = () => {
    let pathName = ''; 
    pathName = utilities.isEmptyOrNullString(this.page) ? 'Page=1' : 'Page='+this.page;
    pathName = pathName + '&ResultsPerPage='+this.pageSize;
    if (this.sortParam && this.sortParam.length > 0) {
      pathName = pathName + '&SortParam='+this.sortParam;
      if (this.sortDirection && this.sortDirection.length > 0) {
        pathName = pathName + '&SortType='+this.sortDirection;
      } else {
        pathName = pathName + '&SortType=asc';
      }
    }    
    this.historyLength = this.historyLength +1;
    pathName = pathName + '&scrollPosition='+this.scrollPosition;   
    let pushUrl = this.pathname +'?'+pathName;
    window.history.pushState(window.history.state, "", pushUrl);
  }

  updateOrder = () => {    
    this.setState({loading:true});
    let{itemList,removeItemnbr,orderDetails,itemDetails} = this.state;
     removeItemnbr = Object.keys(removeItemnbr).map(function(key) {
        return  removeItemnbr[key];
     });
     removeItemnbr.forEach((item,index)=>{
      delete  itemList[item];
     });
     this.promoFlag=false;
     this.flagPromo(itemDetails);
    let payload= {
    "storeID" : this.props.storeId,
    "orderNbr":orderDetails.orderNbr,
    "deleteItemNbr" : removeItemnbr,
    "updateQtyOverrideRetail":itemList,
    "molUserName" : this.props.userName
    }
    !this.promoFlag &&
    restUtils
    .postData(updatePromodetails, payload)
    .then(response => {        
      const{data}=response;
      if(data.UpdateOrdeleteSuccess){
        utilities.showToast('Order updated successfully');
        this.setState({ 
          loading: false, 
          serverError: false });
          return;
      }
    })
    .catch(error => {
      console.log(error);
      this.setState({ loading: false, serverError: true });
    });

  }

  totalPromo=(currentCost,existingCost)=>{
    let{orderDetails}=this.state;
    let newTotalCost =  (orderDetails.totalCost - existingCost) + currentCost;
    orderDetails={...orderDetails, ...{'totalCost' : newTotalCost}}
    this.setState({
      orderDetails
    });

    return newTotalCost;

  }


  getItemDetails = () => {
    const sortArr = { sortParam: this.sortParam, sortType: this.sortDirection };
    let {orderDetails,removeItemnbr,promoShipItemListUpdate,itemList} = this.state;
    let requestPayLoad={}
    requestPayLoad = 
    {
      "userId": this.props.userId,
      "storeId": this.props.storeId,
      "orderNbr":orderDetails.orderNbr,
      "orderRegstrNbr":orderDetails.orderRegstrNbr,
      "rdcNbr":orderDetails.rdcNbr,
       sortBy: sortArr,
      "invoiceNbr":orderDetails.invoiceNbr,
      "pageNumber": this.page,
      "resultPerPage": orderDetails.itemCount,
      "invoiceDateString":orderDetails.invoiceDateString,
      "status":orderDetails.status,
      "promoNbr": orderDetails.relay_nbr,
      aliasSKURetailerGrpID : this.props.aliasSKURetailerGrpID,
    }
    const url = `${PROMO_ITEM_DETAILS}`
    this.setState({ loading: true });
    let{promoNumber,promoOverRide,totalPromo}=this.state;
    restUtils
      .postData(url, requestPayLoad)
      .then(response => {   
        const{data}=response; 
        const paginationPromo = data?.pagination;
        const promoName =data?.promoName;
        let{promoShipItemList}=data;
        removeItemnbr = Object.keys(removeItemnbr).map(function(key) {
          return  removeItemnbr[key];
        });
        promoShipItemListUpdate = promoShipItemList?.filter((item,index)=>{
          if(!utilities.isEmptyOrNullString(promoNumber[item.itemNbr]) && promoNumber[item.itemNbr]){
            item.qty = promoNumber[item.itemNbr];
          }
          if(!utilities.isEmptyOrNullString(promoOverRide[item.itemNbr]) && promoOverRide[item.itemNbr]){
            item.overrideRetail = promoOverRide[item.itemNbr];
          }
            return item.itemNbr !== removeItemnbr[index];
          });
          promoShipItemListUpdate.map((promoqty,index) => {  
          promoNumber = 
          {...promoNumber, [`${promoqty.itemNbr}`]:parseInt(promoqty.qty)}  
          promoOverRide  = {...promoOverRide, [`${promoqty.itemNbr}`]:promoqty.overrideRetail}  
        });  
        const isNoPromoships = !promoShipItemList || promoShipItemList.length===0;  
        this.setState({ 
          itemDetails:promoShipItemListUpdate, 
          promoOverRide,
          exactPromocost:data?.orderTotal,
          totalPromo: data?.orderTotal,
          promoNumber,
          promoName:promoName,
          firstItem: paginationPromo?.firstItem,
          lastItem: paginationPromo?.lastItem,
          currentPage:paginationPromo?.currentPage,
          totalPages:paginationPromo?.totalPages, 
          totalItems:paginationPromo?.totalItems,
          loading: false, 
          serverError: false,
          isNoPromoships });
          this.setBackButtonPath();
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false, serverError: true });
      });
  }

  goToPreviousPage= () => {
    history.go(-parseInt(this.historyLength));
   }

   promoQtyUpdate=(e)=>{
    const { dataset, value } = e.target;
    const { itemNumber,field,itmNumber} = dataset;
    let { promoNumber,promoOverRide,itemDetails,itemList,totalPromo} = this.state;
    const fieldValue = parseFloat(value);
    const packType = itemDetails[itemNumber].pack.substring(0,1).toLowerCase()
    const pack = parseInt(itemDetails[itemNumber].pack.substring(1,))
    let prevExtCost=itemDetails[itemNumber].extCost ? itemDetails[itemNumber].extCost:0;
    switch (field) {
      case 'qty':
        promoNumber[itmNumber] = fieldValue || '';
        itemDetails[itemNumber].qty= fieldValue || '';
        itemDetails[itemNumber].extCost= fieldValue * itemDetails[itemNumber].cost ? fieldValue *  itemDetails[itemNumber].cost : 0;
        if (!fieldValue) {
          utilities.showToast('Please enter a valid quantity', true);
         break;
        }
        if (fieldValue <= 0) {
          utilities.showToast('Quantity should be 1 or more', true);     
          itemDetails[itemNumber].qty = '';
         break;
        }
        if(packType == 'f' && itemDetails[itemNumber].qty % pack){
          utilities.showToast('Quantity should be multiple of ' + pack, true);
          break;
        }
        if (fieldValue > 9999) {
          utilities.showToast(`Quantity should not exceed 9999!`, true);
          return;
        }
      break;
      case 'promoOverride':
        if (fieldValue <= 0) {
          utilities.showToast('Override Retail should be positive number!',true);
          return;
        }
        if (/^(\d{0,5}\.\d{0,2}|\d{1,5})$/.test(value)) {
          promoOverRide[itmNumber] = value || '';
        } else {
          promoOverRide[itmNumber] = fieldValue || '';
        }
        break;
      default:
        promoNumber[itmNumber] =fieldValue || '';
        break;
    }
    itemList = {...itemList, [itmNumber]:promoNumber[itmNumber]+','+promoOverRide[itmNumber]}
      totalPromo = this.totalPromo(itemDetails[itemNumber].extCost,prevExtCost);  
    this.setState({
      promoNumber,
      promoOverRide,
      itemDetails,
      itemList,
      totalPromo
    });
   }

   getSortByFields = () => {
    const selectProps = {className:'sort-criteria combo', onChange: this.changeSortParam, id: 'pl_dropdown', value : this.sortParam};
              
    const options = [
      {props:{value:'itemNum', id: 'itemNbr'},text:'Item #'},
      {props:{value:'model', id: 'model'}, text:'Model #'},
      {props:{value:'upc', id: 'upc'}, text:'UPC#'},
      {props:{value:'advCode', id: 'advCode'}, text:'Adv code'},
      {props:{value:'circularPage', id: 'circularPage'}, text:'Circular Page'},
      {props:{value:'Program', id: 'noteProgram'}, text: 'Program'},
      {props:{value:'title', id: 'shortDesc'}, text: 'Item/Manufacturer'},
    ];

    return(      
      getSelect(selectProps, options)
    );
  }

  
  getWarehouseImage = (imageUrl, img_style) => {
    return renderImages.getImage({ src: imageUrl, className: 'orderImage', style: { display: img_style } })
  }

  getWareHouseRows = (rowProps, img_style, order,index) => {
    const warehouseCols = this.getWareHouseColumns(order, img_style,rowProps,index);
    return (
      <>
      {getTableRow(rowProps, warehouseCols)}
      </>
    )
  }

  removeItemsPromo=(e,itemNbr,index)=>{
    let{itemDetails,removeItemnbr,totalPromo,promoNumber,promoOverRide} = this.state;
    let prevExtCost=itemDetails[index].extCost;
    removeItemnbr ={...removeItemnbr,[itemNbr]:itemNbr }
    itemDetails = itemDetails && itemDetails.filter((itemPromo)=>{
      return parseInt(itemPromo.itemNbr) !== parseInt(itemNbr);
    });
    delete promoNumber[itemNbr];
    delete promoOverRide[itemNbr];
    totalPromo= this.totalPromo(0,prevExtCost);
    this.setState({
      itemDetails,
      removeItemnbr,
      promoOverRide,
      promoNumber,
      totalPromo
    })
  }

  getWareHouseColumns = (order, img_style,rowProps,index) => {
    let{getprefer} = this.props;
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    const showMemberCostAndLbl =  shouldHideCostForPreference(this.props.viewType, getPrefer);
    let{orderDetails,itemDetails}=this.state;
    let statusOpen = orderDetails?.status?.indexOf('OPEN') !== -1
    const tableDataCols = [
      {columnProps:{ className: 't-center', id: 'field_width' }, columnText: this.getWarehouseImage(order.imageUrl, img_style)},
      {columnProps:{ className: 't-left', id: 'field_width' }, columnText: <> <Link to={`/product/${order.itemNbr}`}>{order.itemNbr}</Link></>}
    ];
    if (!utilities.isEmptyOrNullString(this.props.aliasSKURetailerGrpID)) {
      tableDataCols.push({columnProps:{ className: 't-center', id: 'field_width' }, columnText: order.aliasSku});
    }
    tableDataCols.push(
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.upc) },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.advCode) },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.circularPage) },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.model) },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.shortDesc) + "\n" +(order.vendorName? order.vendorName:'')  },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.noteProgram) },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.weight) },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.pack) },)
      if(showMemberCostAndLbl){
      tableDataCols.push(
      { columnProps: { className: 't-center', id: 'field_width' }, columnText:utilities.getCostBasedOnnatvalue(this.props.viewType,order.cost, utilities.getPreferenceHideMask(getprefer))},
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.getCostBasedOnnatvalue(this.props.viewType,order.promoCost, utilities.getPreferenceHideMask(getprefer))},
      )
      }
      if(this.props.viewType !== MEMBER_VIEW && getPrefer === 'c2'){
        tableDataCols.push(
          { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.getCostBasedOnnatvalue(this.props.viewType,order.promoRetail, utilities.getPreferenceHideMask(getprefer))}
        )
      } else if(this.props.viewType !== MEMBER_VIEW && getPrefer === 'c3'){
        tableDataCols.push(
          { columnProps: { className: 't-center', id: 'field_width' }, columnText: ''}
        )
      }else {
        tableDataCols.push(
          { columnProps: { className: 't-center', id: 'field_width' }, columnText: order.promoRetail && order.promoRetail != 'NaN' && parseFloat(order.promoRetail) !== 0 ? '$' + order.promoRetail : ''}
        )
      }
      tableDataCols.push(
      { columnProps: { className: 't-center', id: 'field_width' }, columnText:
        <input className='qty-box'
          data-item-number={index} 
          data-itm-number ={order.itemNbr} 
          data-field={'promoOverride'} 
          disabled={statusOpen ?'':disabled}
          onChange={this.promoQtyUpdate} 
          value={utilities.emptyStringForZero(this.state.promoOverRide[order.itemNbr])}/>},
      { columnProps: { className: 't-center', id: 'field_width' }, columnText:
        <input className='qty-box' data-item-number={index} 
          data-itm-number ={order.itemNbr}  
          disabled={statusOpen ?'':disabled}
          data-field={'qty'} 
          onChange={this.promoQtyUpdate} 
          value={this.state.promoNumber[order.itemNbr]}/> },)
      if(showMemberCostAndLbl){
      tableDataCols.push(
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(utilities.getCostBasedOnnatvalue(this.props.viewType,order.extCost ?order.extCost:0,  utilities.getPreferenceHideMask(getprefer))) },)
      }
      tableDataCols.push(
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: 
      <>
      {utilities.hyphenForNull(order.statusVal)}
      </> },
    );
    return tableDataCols;    
  }

  getWarehouseHeaders = () => {
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    const showMemberCostAndLbl = shouldHideCostForPreference(this.props.viewType, getPrefer);
    const tableHeader = [
      {props:{className:'allOdrHeader', id:'no_border'}, text: ''},
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'Item #'}
    ];

    if(!utilities.isEmptyOrNullString(this.props.aliasSKURetailerGrpID)) {
      tableHeader.push({props:{className:'allOdrHeader', id:'no_border'}, text: 'Alternate SKU#'});
    }
    
    tableHeader.push(
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'UPC #'},
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'ADV Code #'},
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'Circular Page #'},
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'Model #'},
      {props:{className:'allOdrHeader', id:'promo_title_width'}, text: 'Item/Manufacturer'},
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'Program'},
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'Weight'},
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'Pack'},)
      if(showMemberCostAndLbl){
      tableHeader.push(
      {props:{className:'allOdrHeader', id:'no_border'}, text: utilities.getLabelsByType(this.props.viewType,"COST", getPrefer)},
      {props:{className:'allOdrHeader', id:'no_border'}, text: utilities.getLabelsByType(this.props.viewType,"PROMO_COST", getPrefer)},
      )
      }
      if(this.props.viewType !== MEMBER_VIEW && getPrefer === 'c2'){
        tableHeader.push(
          {props:{className:'allOdrHeader', id:'no_border'}, text: utilities.getLabelsByType(this.props.viewType,"PROMO_RETAIL", getPrefer)}
        )
      } else if(this.props.viewType !== MEMBER_VIEW && getPrefer === 'c3'){
        tableHeader.push(
          {props:{className:'allOdrHeader', id:'no_border'}, text: ''}
        )
      }else {
        tableHeader.push(
          {props:{className:'allOdrHeader', id:'no_border'}, text: 'Promo Retail'}
        )
      }
      tableHeader.push(
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'Override retail'},
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'Qty'},)
      if(showMemberCostAndLbl){
      tableHeader.push(
      {props:{className:'allOdrHeader', id:'no_border'}, text: utilities.getLabelsByType(this.props.viewType,"EXTENDED_COST", getPrefer)},)
      }
      tableHeader.push(
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'Status'}
    );
    return(getTableRow({},tableHeader,true))
  }
  onOrderpagesize=e=>{
    this.pageSize = e.target.value;
    this.page = 1;
    this.getItemDetails();
  }

  onPageChange = e => {
    let{itemDetails,orderDetails}=this.state;
    let statusOpen = orderDetails?.status?.indexOf('OPEN') !== -1;
    let queryPage = e.currentTarget.text;
    let {currentPage,totalPages}=this.state;
    if (queryPage === 'Next') {
      queryPage =
      currentPage < totalPages
          ? parseInt(currentPage) + 1
          : currentPage;
    }
    if (queryPage === 'Prev') {
      queryPage = currentPage === 1 ? 1 :currentPage - 1;
    }
    if (queryPage === '»') {
      queryPage = currentPage;
    }
    if (queryPage === '«') {
      queryPage = 1;
    }
    this.page = queryPage;
    this.scrollPosition = window.pageYOffset;
    window.scrollTo(0, 0);
    if(statusOpen){
      this.promoFlag=false;
      this.flagPromo(itemDetails);
      !this.promoFlag && this.getItemDetails();
    }
    else{
      this.getItemDetails();
    }
  };

  promoMobilemode=(order,orderDetails,index,imageUrl,img_style)=>{
    let statusOpen = orderDetails?.status?.indexOf('OPEN') !== -1;
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    const showMemberCostAndLbl = shouldHideCostForPreference(this.props.viewType, getPrefer);
    return (
      <div className='all-orders-card'>
          <table id='order-card-table'>
          <tr>
          <td className='t-center' id='field_width'>
              {renderImages.getImage({ src: imageUrl, className: 'orderImage', style: { display: img_style } })}</td>
              </tr>
          <tr>
              <td className='order-card-field'>Item #</td>
              <td><Link to={`/product/${order.itemNbr}`}>{order.itemNbr}</Link></td>
          </tr>
          {!utilities.isEmptyOrNullString(this.props.aliasSKURetailerGrpID) &&
              <>
              <tr>
              <td className='order-card-field'>Alternate SKU#</td>
              <td>{order.aliasSku}</td>
              </tr>
              </>
          }
          <tr>
              <td className='order-card-field'>UPC #</td>
              <td>{utilities.hyphenForNull(order.upc)}</td>
          </tr>
          <tr>
              <td className='order-card-field'>ADV Code #</td>
              <td>{utilities.hyphenForNull(order.advCode)}</td>
          </tr>
          <tr>
              <td className='order-card-field'>Circular Page #</td>
              <td>{utilities.hyphenForNull(order.circularPage)}</td>
          </tr>
          <tr>
              <td className='order-card-field'>Model #</td>
              <td>{utilities.hyphenForNull(order.modelNum)}</td>
          </tr>
          <tr>
              <td className='order-card-field'>Title</td>
              <td >{utilities.hyphenForNull(order.shortDesc)}</td>
          </tr>
          <tr>
              <td className='order-card-field'>Program</td>
              <td >{utilities.hyphenForNull(order.noteProgram)}</td>
          </tr>
          <tr>
              <td className='order-card-field'>Weight</td>
              <td >{utilities.hyphenForNull(order.weight)}</td>
          </tr>
          <tr>
              <td className='order-card-field'>Pack</td>
              <td >{utilities.hyphenForNull(order.pack)}</td>
          </tr>
          {showMemberCostAndLbl && <tr>
              <td className='order-card-field'>{utilities.getLabelsByType(this.props.viewType,"COST", getPrefer)}</td>
              <td >{this.roundNull(order.cost, 'Member Cost black')}</td>
          </tr>}
          { showMemberCostAndLbl && <tr>
              <td className='order-card-field'>{utilities.getLabelsByType(this.props.viewType,"PROMO_COST", getPrefer)}</td>
              <td>{ utilities.changePriceToNumber(order.promoCost)}</td>
          </tr>}
          {showMemberCostAndLbl && <tr>
              <td className='order-card-field'>{utilities.getLabelsByType(this.props.viewType,"PROMO_RETAIL", getPrefer)}</td>
              <td>{utilities.changePriceToNumber(order.promoRetail)}</td>
          </tr>}
          <tr>
              <td className='order-card-field'>Override retail</td>
              <td>
                <input className='qty-box' data-item-number={index} 
                    data-itm-number ={order.itemNbr}  
                    disabled={statusOpen ?'':disabled}
                    data-field={'promoOverride'} 
                    onChange={this.promoQtyUpdate} 
                    value={utilities.emptyStringForZero(this.state.promoOverRide[order.itemNbr])}/></td>
          </tr>
          <tr>
              <td className='order-card-field'>Quantity</td>
              <td> <input className='qty-box' data-item-number={index} 
                    data-itm-number ={order.itemNbr}  
                    disabled={statusOpen ?'':disabled}
                    data-field={'qty'} 
                    onChange={this.promoQtyUpdate} 
                    value={this.state.promoNumber[order.itemNbr]}/>
                </td>
          </tr>
          {showMemberCostAndLbl && <tr>
              <td className='order-card-field'>{utilities.getLabelsByType(this.props.viewType,"EXTENDED_COST", getPrefer)}</td>
              <td>{this.roundNull(order.extCost, 'Member Cost black')}</td>
          </tr>}
          <tr>
              <td className='order-card-field' >Status</td>
              <td>{utilities.hyphenForNull(order.statusVal )}
              </td>
          </tr>
          </table>
      </div>
      )

  }

  render() {
    let {orderDetails,itemDetails,totalPromo,promoName} = this.state;
    let statusOpen = orderDetails?.status?.indexOf('OPEN') !== -1
    let{getprefer}=this.props;
    const img_style = this.state.img_txt_to_display === 'Hide Images' ? 'block' : 'none';
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    const showMemberCostAndLbl =  shouldHideCostForPreference(this.props.viewType, getPrefer);
    return (
      this.state.serverError ? <InternalServerError /> :
        <div className='page-wrap' ref={node => (this.componentRef = node)}>
          <div>
          <span className='page-header'>Auto-Ship</span>
          <br/>
          </div>
          {this.state.loading &&
            <div>
              <Loader active />
            </div>
          }

          <div className='sort_by'>
            <span className='filter-label'>Sort By</span>
            {this.getSortByFields()}
            { getSortByDirection(this.changeSortDirection,null, null, this.sortDirection)} 
            <span className='padding_left'>
            <ExcelExport
                fileName='PromoItemDetails'
                className='exportPDF exportPdfPLP'
                sortParam = {this.sortParam}
                sortDirection = {this.sortDirection}
                orderDetails = {this.state.orderDetails}
                storeId = {this.props.storeId}
                userId = {this.props.userId}
                page = {this.page}
                orderResults={itemDetails} 
                itemsData={this.state.itemDetails}
                viewType={this.props.viewType}
                getprefer={
                  this.props.getprefer &&
                  this.props.getprefer.customerView
                  ? this.props.getprefer.customerView
                  : 'c1'
                }
                viewTypepromo={getprefer}
                promoOverRide={this.state.promoOverRide}
                aliasSKURetailerGrpID={this.props.aliasSKURetailerGrpID}
            />
            </span>
          </div>
          <br />
          <div className='promo-options'>
            <div className="optio-text">
            <h4>Promo # - {orderDetails.promoName ? orderDetails.promoName : orderDetails.relay_nbr}</h4>
            </div>
            <span className='image-option'>
              <span onClick={this.showHideImages} id='hideShowImages'>{this.state.img_txt_to_display}</span>
              <span id='back_button' className='underline' onClick={this.goToPreviousPage}>Back to Auto-Ship Orders</span>
            </span>
          </div>
          <div className='order-pagination pagination-padding pagination-mob-flex'>
            <span className='OrderTotalitem'>
              {this.state.totalItems} Items
            </span>
          </div>
          <div>
            <span className='promo-disclaimer'>
                Please ensure that at least one unit or an F-Pack is selected for an item, you cannot cancel off items from this order.  See MOL for claims return rules.
            </span>
          </div>
          <div>
            <span className='promo-disclaimer'>
              Reminder:  when making adjustments to your Auto Ship items to select the update button.  If you leave the page without updating, your changes will not be retained.
            </span>
          </div>
          {this.state.isNoPromoships?
                            <div className='no_prdcts_display'>
                                <h1>No Auto-Ship Order Available!</h1>
                            </div>
                        :
          <div className={window.innerWidth > 1024 ? 'displayFlex allOrdersTable orderstatusMargin' : 'promo-allitems allOrdersTableItem'}>
            <Responsive minWidth='1025'>
              <div className='eighteen wide column'>
              <div className='wrapPromoBtn'>
                   {statusOpen &&
                         <button className='compare-btn promo-updatebtn'  
                           onClick={() => this.updateOrder()}
                           disabled={this.state.disableUpdateOrderbtn}>
                               Update Order
                         </button> 
                   }
                 </div>
              {getTable({class:"ui celled table all-wh-table", id:'font-size-orders'},this.getWarehouseHeaders(), itemDetails, this.getWareHouseRows, {className:'wh-row'}, img_style)}                
              </div>    
              <div className="promoupdate">
                <div className='promo-blank'>

                </div>
                { showMemberCostAndLbl && <div className='promo-total'>
                  <p>{utilities.getLabelsByType(this.props.viewType,"ORDER_TOTAL", getPrefer)} :
                  <span className='cost_red_color' style={{marginLeft: '0.5rem'}}>            
                    {orderDetails?.status.toLowerCase() === 'cancelled' ? '-' :
                    <MaskPrice
                      field = 'Member Cost black'
                      getprefer={utilities.getPreferenceHideMask(getprefer)}
                      viewType={this.props.viewType}
                      fieldVal={this.state.totalPromo}
                    />}
                  </span>
                  </p>
                  </div>}
                </div>   
            </Responsive>
            <Responsive maxWidth='1024'>
              {this.state.itemDetails && this.state.itemDetails.map((order,index) => {
                const imageUrl = imagePromo + order.itemNbr +imageColor;
                return this.promoMobilemode(order,orderDetails,index,imageUrl,img_style)  
              })}
          <div className="promoupdate">
            <div className='promo-blank'>

            </div>
            <div className='promo-total'>
              { statusOpen &&
                <button className='compare-btn promo-updatebtn'  
                  onClick={() => this.updateOrder()}
                  disabled={this.state.disableUpdateOrderbtn}>
                      Update Order
                </button> 
                }
              { showMemberCostAndLbl && <p>{utilities.getLabelsByType(this.props.viewType,"ORDER_TOTAL", getPrefer)} :  
              <span className='cost_red_color' style={{marginLeft: '0.5rem'}}>            
                {orderDetails?.status.toLowerCase() === 'cancelled' ? '-' :
                <MaskPrice
                  field = 'Member Cost black'
                  getprefer={utilities.getPreferenceHideMask(getprefer)}
                  viewType={this.props.viewType}
                  fieldVal={this.state.totalPromo}
                />}
              </span>
              </p>}
              </div>
            </div>
            </Responsive>
          </div>
        }
        </div>
    )
  }
}


const mapStateToProps = state => {
  const storeId = state.SessionReducer.storeId;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    storeId,
    getprefer:state?.preference?.listData,
    userId:state?.SessionReducer?.UserId,
    userName: state?.SessionReducer?.userName,
    orders: state?.cart?.orders,
    aliasSKURetailerGrpID:state?.SessionReducer?.address ? state?.SessionReducer?.address?.aliasSKURetailerGrpID : '',
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({}, dispatch)
  );
};

export default connect( mapStateToProps, mapDispatchToProps)(PromoItemDetails);
