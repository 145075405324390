import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Segment, Table, Checkbox, Grid, GridRow, GridColumn } from 'semantic-ui-react';
import renderImages from '../../common/RenderImages';
import Dialog from 'react-dialog';
import { MaskPrice } from '../../common/MaskPrice';
import { TierIcon, ProductIndicators } from '../../common/TierIcon';
import utilities from '../../../utils/utilities';
import { closeModal, addimg, delimg } from '../../common/productHandler';
import { addProduct, createNAddCart, orderTypes } from '../../Cart/actions';
import { orderNames } from '../../Cart/constants';
import AddToCartModal from '../../Cart/AddToCartModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from '../../../history';
import classnames from 'classnames';
import { DISCOUNT_CITY } from '../../Categories/constants';
import SpecTable from './SpecTable';
import config from '../../../config';

class DcProductListing extends Component {
    constructor(props) {
        super(props);
        this.closeModal = closeModal.bind(this);
        this.addimg = addimg.bind(this);
        this.delimg = delimg.bind(this);

        this.state = {
            visible: false,
            showSpec: '',
            showToggle: false,
            item: '',
            click: '',
            expand: '',
            productSelectedForModal: null,
            indexForModal: 0,
            isAddToCartModalOpen: false,
            viewOnly: this.props.viewType,
            toastMessage: '' //  Product added to cart..
        };
    }

    expand = num => {
        this.setState({
            expand: 'expan',
            item: num
        });
    };

    openCartModal = (product, index) => {
        this.setState({
            productSelectedForModal: product,
            indexForModal: index,
            isAddToCartModalOpen: true
        });
    };

    closeCartModal = () => {
        this.setState({ isAddToCartModalOpen: false });
    };

    closeModalexpand = () => {
        this.setState({
            expand: '',
            item: ''
        });
    };
     
    toggleDiv = divId => {
        this.setState(prevState => {
            return { [divId]: !prevState[divId] };
        });
    };

    openModal(data) {
        this.setState({
            visible: true,
            item: data,
            click: 'clicked',
            expand: ''
        });
        this.props.blurfun(true);
    };

    handleAddToCart = (product, index) => {
        const { tableType, addToCart, isShowAllWarehouse, storeId } = this.props;
        const qty = this.props.quantities[index];
        const or = this.props.overrideRetails[index];
        const rdcNum = utilities.rdcBasedonCentralship(product.rdctype, product.CsRDC, this.props.rdc);
        if (isShowAllWarehouse && qty > product.Available) {
            history.push({
                pathname: `/all-warehouses/${product.Item_Number}`,
                state: { fromPLP: true }
            });
            return;
        }
        if (!this.props.validateProductQuantity(
            product.S_Pack_Type,
            product.S_Pack_Qty,
            this.props.quantities[index]
        )) {
            return;
        }

        if (tableType === DISCOUNT_CITY) {
            addToCart(
                product.Item_Number,
                index,
                product.relay_nbr,
                product.Vendor_Id,
                product.S_Pack_Type,
                product.S_Pack_Qty,
                storeId
            );
        }
    }
    renderImage=(product)=>{
        return renderImages.getImage({
            src: product.Image_File_Name
                ? product.Image_File_Name
                : 'https://images.truevalue.com/getimage.asp?id=' +
                product.Item_Number +
                '&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2',
            style: { display: this.props.img_style },
            alt: product.Image_File_Name,
            className: 'ui image image_lisitng_grid',
            onMouseEnter: () => {
                this.addimg(
                    'addimg',
                    product.Item_Number
                        ? product.Item_Number
                        : product.item_id
                );
            }
        })
    }

    getLongDesc = (product) => {
        let longDesc = '';
        if(config.showMediumDesp){
            longDesc = product.Product_Title || product.Long_Description || product.Product_Name;
        }
        else {
            longDesc = product.Long_Description;
        }
        let Item_num = product.Item_Number;
        return(
          longDesc && (
            <p className='listingDescription'>
              {longDesc.length < 250
                ? longDesc.substr(0,1).toUpperCase() + longDesc.substr(1,longDesc.length).toLowerCase() 
                : longDesc.substr(0,1).toUpperCase() + longDesc.substr(1,242).toLowerCase()  + '...'}
              <u className='cursor_p'
                  onClick={() => this.expand(Item_num)}
              >
                {longDesc.length < 250
                  ? ''
                  : 'more'}
              </u>
            </p>
          )
        )
    }
    
    getLongDescription = (product) => {
        let longDesc = '';
        if(config.showMediumDesp){
            longDesc = product.Product_Title || product.Long_Description || product.Product_Name;
        }
        else {
            longDesc = product.Long_Description;
        }
        if(longDesc){
            longDesc =  longDesc.substr(0,1).toUpperCase() + longDesc.substr(1,longDesc.length).toLowerCase()
        }
        else{
            longDesc = "";
        }
        return longDesc;
    }

    render() {
        const {
            quantities,
            viewType,
            overrideRetails,
            handleChange,
            handleChangeblur,
            tableType,
            categoryData: products,
            addToCart,
            isShowAllWarehouse,
            assortmentId
        } = this.props;
        const isDiscountCity = tableType === DISCOUNT_CITY;
        const link = '/discountCitypalletListing/';
        const linkPdp = '/product/';
        return (
            <>
                <AddToCartModal
                    isAddtoCartModalopen={this.state.isAddToCartModalOpen}
                    closeCartModal={this.closeCartModal}
                    addToCart={addToCart}
                    product={this.state.productSelectedForModal}
                    index={this.state.indexForModal}
                    qty={quantities[this.state.indexForModal]}
                    or={overrideRetails[this.state.indexForModal]}
                ></AddToCartModal>
                {products &&
                    products.map((product, index) => {
                        const isDiscontinuedHideAddToCart = product.whStockStatus === 'DIS' ? true : false
                        return (
                            <Segment className=''
                                style={{ position: 'static', margin: '1rem 1rem' }}
                                key={product.Item_Number}
                            >
                                {this.state.expand === 'expan' &&
                                    this.state.item === product.Item_Number && (
                                        <div className='DescriptionDialog' onMouseLeave={this.closeModalexpand}>
                                            <Dialog width='1' height='1' position='absolute'>
                                            { this.getLongDescription(product)}
                                            </Dialog>
                                        </div>
                                )}
                                <div className="seq-right">
                                    <span className='compare-n-fav padding_right'>
                                        SEQ# :{product.seq_nbr}
                                    </span>
                                </div>
                                <div
                                    className={
                                        this.state.click === 'clicked'
                                            ? 'ui stackable two column grid blur_state'
                                            : 'ui stackable two column grid'
                                    }
                                >
                                    {this.props.palletItem ?
                                        <>
                                        <div className='six wide column pallet-leftsection left-top-padding' onMouseLeave={() => { this.delimg('delimg'); }}>
                                        <div className='image-dcleft'>
                                            <Link
                                                onClick={this.props.setBackButtonPath}
                                                to={{
                                                    pathname: linkPdp + '100024',
                                                    state: {
                                                        palletItem: this.props.palletItem
                                                    }
                                                }}
                                                className={
                                                    this.props.img_style === 'none'
                                                        ? 'disable_img_click'
                                                        : 'card_list_img'
                                                }
                                            >
                                                {this.renderImage(product)}
                                            </Link>
                                        </div>
                                        <GridRow>
                                                <div>
                                                    <Link
                                                        onClick={this.props.setBackButtonPath}
                                                        to={{
                                                            pathname: linkPdp + '100024',
                                                            state: {
                                                                palletItem: this.props.palletItem
                                                            }
                                                        }}
                                                    >
                                                        <b className='First_level_details'>
                                                            {product.shortDesc || product.Product_Name || product.Product_Title}
                                                        </b>
                                                    </Link>
                                                    <div className='vendor-spanallitems'>
                                                        <Link
                                                            onClick={this.props.setBackButtonPath}
                                                            to={{
                                                                pathname: product.Vendor_Name
                                                                    ? '/vendor/' +
                                                                    utilities.replaceAll(
                                                                        product.Vendor_Name,
                                                                        '/',
                                                                        '%2F'
                                                                    ) + '/' + (product.Vendor_Id || product.vendorID || product.vendor_id)
                                                                    : '#',
                                                                state: { vendorId: product.Vendor_Id }
                                                            }}
                                                        >
                                                            {product.Vendor_Name}
                                                        </Link>
                                                    </div>
                                                </div>
                                                { this.getLongDesc(product)}
                                                <div className='indicators-div'>
                                                    <ProductIndicators product={product} isPLP='PLP' />
                                                </div>
                                            </GridRow>
                                    </div>
                                    <div className='ten wide column'>
                                        <Grid>
                                            <GridRow>
                                                <Grid.Column computer={15}>
                                                    <Table stackable className='very basic fixed' style={{ fontWeight: '1000' }}>
                                                        <Table.Body className='listingProduct' style={{ fontSize: '0.8rem' }}>

                                                            <CustomTableView
                                                                viewPOG={this.props.multiStoreDetails.viewPOG}
                                                                PogMbr={this.props.multiStoreDetails.PogMbr}
                                                                CatMbr={this.props.multiStoreDetails.CatMbr}
                                                                getprefer={
                                                                    this.props.getprefer &&
                                                                        this.props.getprefer.customerView
                                                                        ? this.props.getprefer.customerView
                                                                        : 'c1'
                                                                }
                                                                multiStoreDetails={this.props.multiStoreDetails}
                                                                tableType={tableType}
                                                                assortmentId={assortmentId}
                                                                product={product}
                                                                viewType={this.props.viewType}
                                                                handleQtyChange={handleChange}
                                                                handleQtyChangeblur={handleChangeblur}
                                                                index={index}
                                                                palletItem={this.props.palletItem}
                                                                quantities={quantities}
                                                                overrideRetail={overrideRetails[index]}
                                                                title={this.props.title}
                                                                listType={this.props.listType}
                                                                isShowAllWarehouse={isShowAllWarehouse}
                                                                access={this.props.multiStoreDetails.access}
                                                                isCloseoutItems={this.props.isCloseoutItems}
                                                                RAMarketFlag={this.props.RAMarketFlag}
                                                            />

                                                            <Table.Row>
                                                                <Table.Cell colSpan={'2'} className='t-center'>
                                                                    <span className='restricted-product'>
                                                                        {(product.Restricted || product.itemRestricted || product.restricted === 'True')
                                                                            ? 'Restricted Product'
                                                                            : ''}
                                                                    </span>
                                                                </Table.Cell>
                                                                {(isDiscountCity) && (
                                                                    <Table.Cell colSpan={2} />
                                                                )}
                                                            </Table.Row>
                                                        </Table.Body>
                                                    </Table>

                                                </Grid.Column>
                                            </GridRow>
                                        </Grid>
                                    </div>
                                        </>
                                        :
                                        <>
                                        <div className='one wide column left-top-padding' onMouseLeave={() => { this.delimg('delimg'); }}>
                                        <div className='card_list_wrap'>
                                            <Link
                                                onClick={this.props.setBackButtonPath}
                                                to={{
                                                    pathname: link + product.Item_Number,
                                                    state: {
                                                        palletDesc:product?.shortDesc || product.Product_Name || product.Product_Title,
                                                        PalletSKU:'266-058',
                                                        palletModelnumber:product.Model,
                                                        palletUpc:product.UpcNum,
                                                        palletCost:product.spc_cost,
                                                        palletMbrCost:product.Cost,
                                                        palletSavings:product.Savings,
                                                        palletPricing:product.pricing,
                                                        palletVendorname:product.Vendor_Name,
                                                        palletPack:product?.S_Pack_Type +product?.S_Pack_Qty,
                                                        palletRelayNbr:product.RelayNbr,
                                                        palletPromoNbr:product.PromoNbr,
                                                    }
                                                }}
                                                className={
                                                    this.props.img_style === 'none'
                                                        ? 'disable_img_click'
                                                        : 'card_list_img'
                                                }
                                            >
                                                {this.renderImage(product)}
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='fifteen wide column'>
                                        <Grid>
                                            <GridRow>
                                                <div className='expand_list'>
                                                    <Link
                                                        onClick={this.props.setBackButtonPath}
                                                        to={{
                                                            pathname: link + product.Item_Number,
                                                            state: {
                                                                palletDesc:product?.shortDesc || product.Product_Name || product.Product_Title,
                                                                PalletSKU:'266-058',
                                                                palletModelnumber:product.Model,
                                                                palletUpc:product.UpcNum,
                                                                palletCost:product.spc_cost,
                                                                palletMbrCost:product.Cost,
                                                                palletSavings:product.Savings,
                                                                palletPricing:product.pricing,
                                                                palletVendorname:product.Vendor_Name,
                                                                palletPack:product?.S_Pack_Type +product?.S_Pack_Qty,
                                                                palletRelayNbr:product.RelayNbr,
                                                                palletPromoNbr:product.PromoNbr,
                                                            }
                                                        }}
                                                    >
                                                        <b className='First_level_details'>
                                                            {product.shortDesc || product.Product_Name || product.Product_Title}
                                                        </b>
                                                    </Link>
                                                    <b className='vendor-span'>
                                                        <Link
                                                            onClick={this.props.setBackButtonPath}
                                                            to={{
                                                                pathname: product.Vendor_Name
                                                                    ? '/vendor/' +
                                                                    utilities.replaceAll(
                                                                        product.Vendor_Name,
                                                                        '/',
                                                                        '%2F'
                                                                    ) + '/' + (product.Vendor_Id || product.vendorID || product.vendor_id)
                                                                    : '#',
                                                                state: { vendorId: product.Vendor_Id }
                                                            }}
                                                        >
                                                            {product.Vendor_Name}
                                                        </Link>
                                                    </b>
                                                    <span className='expand_lable_list'>
                                                        <label className='dc_expand_label_list'>{this.state.showSpec === 'showSpec' + product.Item_Number && this.state.showToggle ? 'Collapse' : 'Expand'}</label>
                                                        <i className={classnames(
                                                            `${this.state.showSpec === 'showSpec' + product.Item_Number && this.state.showToggle ? 'minus' : 'plus'}`,
                                                            'icon',
                                                            'fl-r'
                                                        )} id='expand_discountcity_list'
                                                            onClick={() => { this.toggleDiv('showToggle'); this.setState({ showSpec: 'showSpec' + product.Item_Number }) }}
                                                        ></i>
                                                    </span>
                                                </div>
                                                {product.Long_Description && (
                                                    <p className='listingDescription'>
                                                        {product.Long_Description.length < 250
                                                            ? product.Long_Description
                                                            : product.Long_Description.substr(0, 242) + '...'}
                                                        <u className='cursor_p'
                                                            onClick={() => this.expand(product.Item_Number)}
                                                        >
                                                            {product.Long_Description.length < 250
                                                                ? ''
                                                                : 'more'}
                                                        </u>
                                                    </p>
                                                )
                                                }
                                                <div className='indicators-div'>
                                                    <ProductIndicators product={product} isPLP='PLP' />
                                                </div>  
                                            </GridRow>
                                            <GridRow>
                                                <Grid.Column computer={14}>
                                                    <Table stackable className='very basic fixed' style={{ fontWeight: '1000' }}>
                                                        <Table.Body className='listingProduct' style={{ fontSize: '0.8rem' }}>

                                                            <CustomTableView
                                                                viewPOG={this.props.multiStoreDetails.viewPOG}
                                                                PogMbr={this.props.multiStoreDetails.PogMbr}
                                                                CatMbr={this.props.multiStoreDetails.CatMbr}
                                                                getprefer={
                                                                    this.props.getprefer &&
                                                                        this.props.getprefer.customerView
                                                                        ? this.props.getprefer.customerView
                                                                        : 'c1'
                                                                }
                                                                multiStoreDetails={this.props.multiStoreDetails}
                                                                tableType={tableType}
                                                                assortmentId={assortmentId}
                                                                product={product}
                                                                viewType={this.props.viewType}
                                                                handleQtyChange={handleChange}
                                                                handleQtyChangeblur={handleChangeblur}
                                                                index={index}
                                                                palletItem={this.props.palletItem}
                                                                quantities={quantities}
                                                                overrideRetail={overrideRetails[index]}
                                                                title={this.props.title}
                                                                listType={this.props.listType}
                                                                isShowAllWarehouse={isShowAllWarehouse}
                                                                access={this.props.multiStoreDetails.access}
                                                                isCloseoutItems={this.props.isCloseoutItems}
                                                                RAMarketFlag={this.props.RAMarketFlag}
                                                            />

                                                            <Table.Row>
                                                                <Table.Cell colSpan={'2'} className='t-center'>
                                                                    <span className='restricted-product'>
                                                                        {(product.Restricted || product.itemRestricted || product.restricted === 'True')
                                                                            ? 'Restricted Product'
                                                                            : ''}
                                                                    </span>
                                                                </Table.Cell>
                                                                {(isDiscountCity) && (
                                                                    <Table.Cell colSpan={2} />
                                                                )}
                                                            </Table.Row>
                                                        </Table.Body>
                                                    </Table>

                                                </Grid.Column>
                                                <Grid.Column computer={2
                                                }> <div id='plp_add_to_cart'>
                                                        {this.props.multiStoreDetails.access !== 1 && !isDiscontinuedHideAddToCart && (
                                                            <button
                                                                className='red ui button'
                                                                style={{ width: '-webkit-fill-available' }}
                                                                id='dc_list_add_to_card'
                                                                onClick={() =>
                                                                    this.handleAddToCart(product, index)
                                                                }
                                                            >
                                                                Add to Cart
                                                            </button>
                                                        )}
                                                    </div>
                                                </Grid.Column>
                                            </GridRow>
                                        </Grid>
                                    </div>
                                        </> 
                                    }
                                     <div className='sixteen wide column' id='specTableGrid'>
                                    {this.state.showToggle && this.state.showSpec === 'showSpec' + product.Item_Number
                                                ? (

                                                    <SpecTable
                                                        product={this.props.categoryData}
                                                        multiStoreDetails={this.props.multiStoreDetails}
                                                        viewType='list' />

                                                )
                                                : (
                                                    <tbody />
                                                )
                                            }
                                    </div>
                                </div>
                            </Segment>
                        );
                    })}
            </>
        );
    }
}

const mapStateToProps = state => {
    const carts = state.cart.orders.allCarts;
    return {
        carts,
        currentCartType: state.cart?.orders?.currentCart?.cartInfoName,
        rdc: state.SessionReducer.rdc,
        whCarts: carts.filter(cart => cart?.cartInfoName === 'REGULAR'),
        getprefer: state.preference.listData,
        multiStoreDetails: state.SessionReducer.multiStoreDetails
    };
};

function mapDispatchToProps(dispatch) {
    return Object.assign(
        { dispatch },
        bindActionCreators(
            {
                addProduct,
                createNAddCart
            },
            dispatch
        )
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(DcProductListing);

const CustomTableView = ({
    product,
    viewType,
    tableType,
    palletItem,
    handleQtyChange,
    handleQtyChangeblur,
    index,
    quantities,
    overrideRetail,
    title,
    listType,
    isShowAllWarehouse,
    getprefer,
    assortmentId,
    access,
    CatMbr,
    viewPOG,
    PogMbr,
    multiStoreDetails,
    RAMarketFlag
}) => {
    const quantity = quantities[index];

    let tbl_vals = [];
    if(!palletItem){
        tbl_vals = [
            ...((access !== 1 || (access === 1 && CatMbr))
                ? [
                    [
                        { label: 'Pallet Location:', val: 'C 6006' },
                        { label: 'Upc', val: product.UpcNum },
                        { label: 'Member cost ', val: '$30.00' },
                        { label: 'Dating', val: '30 days' },
    
                    ],
                    [
                        { label: 'Pallet SKU:', val: '266-058' },
                        { label: 'Retail Fine Line:', val: 'H07-65' },
                        { label: 'Market cost ', val: '$100.72' },
                        {
                            label: 'Pack', val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                                product.S_Pack_Qty ? product.S_Pack_Qty : ''
                                }`
                        },
    
                    ],
                    [
                        { label: 'Model#:', val: product.Model },
                        { label: 'Sugg. Rratial:', val: overrideRetail },
                        { label: 'Savings:', val: utilities.hyphenForNull(product.Savings) }
                    ],
                    [
                        { label: 'Ship weeks:' },
                        {
                            label: 'MM/DD',
                            type: 'qty1',
                            is_text_box: true,
                            val: quantity[0]
                        },
                        {
                            label: 'MM/DD',
                            type: 'qty2',
                            is_text_box: true,
                            val: quantity[1]
                        },
                        {
                            label: 'MM/DD',
                            type: 'qty3',
                            is_text_box: true,
                            val: quantity[2]
                        },
                        {
                            label: 'MM/DD',
                            type: 'qty4',
                            is_text_box: true,
                            val: quantity[3]
                        },
    
                    ]
                ]
                :
                [
                    [
                        { label: 'Pallet Location:', val: 'C 6006' },
                        { label: 'Upc', val: product.UpcNum },
                        { label: 'Dating', val: '30 days' },
    
                    ],
                    [
                        { label: 'Pallet SKU:', val: '266-058' },
                        { label: 'Retail Fine Line:', val: 'H07-65' },
                        {
                            label: 'Pack', val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                                product.S_Pack_Qty ? product.S_Pack_Qty : ''
                                }`
                        },
    
                    ],
                    [
                        { label: 'Model#:', val: product.Model },
                        { label: 'Savings:', val: utilities.hyphenForNull(product.Savings) }
                    ],
                    [
                        { label: 'Ship weeks:' },
                        {
                            label: 'MM/DD',
                            type: 'qty1',
                            is_text_box: true,
                            val: quantity[0]
                        },
                        {
                            label: 'MM/DD',
                            type: 'qty2',
                            is_text_box: true,
                            val: quantity[1]
                        },
                        {
                            label: 'MM/DD',
                            type: 'qty3',
                            is_text_box: true,
                            val: quantity[2]
                        },
                        {
                            label: 'MM/DD',
                            type: 'qty4',
                            is_text_box: true,
                            val: quantity[3]
                        },
                        {
                            label: 'AddtoCart',
                            type: 'Cart'
                        }
                    ]
    
                ]
            )
        ]; 
    }
    else{
    tbl_vals = [
        ...((access !== 1 || (access === 1 && CatMbr))
            ? [
                [
                    {  label: 'Item SKU:', val: '266-058' },
                    {  label: 'Market cost ', val: '$100.72'},
                    { label: 'Model#:', val: product.Model },

                ],
                [
                    { label: 'Member cost ', val: '$100.72'},
                    { label: 'NoN Stocked:', val: 'N' },
                    { label: 'Quantity ', val: '6' },

                ]
            ]
            :
            [
                [
                    {  label: 'Item SKU:', val: '266-058' },
                    {  label: 'Market cost ', val: '$100.72'},
                    { label: 'Model#:', val: product.Model },

                ],
                [
                    { label: 'Member cost ', val: '$100.72'},
                    { label: 'NoN Stocked:', val: 'N' },
                    { label: 'Quantity ', val: '6' },

                ]
            ]
        )
    ];
}
    return (
        <>
            {tbl_vals.map((item, i) => (
                <>
                    <Table.Row>
                        {item.map((cell, i) => (
                            <>
                                {cell.label === 'RESTRICTED' ? (
                                    <Table.Cell colSpan='2' className={cell.className}>{cell.val}</Table.Cell>
                                ) : (
                                        <>
                                            <Table.Cell className='First_level_details'>
                                                <span>{cell.label}</span>
                                            </Table.Cell>
                                            {cell.is_text_box ? (
                                                cell.type === 'qty' ? (
                                                    <Table.Cell>
                                                        <input
                                                            type='text'
                                                            id={'qty-box' + product.Item_Number}
                                                            data-field='qty'
                                                            data-item-number={index}
                                                            data-pack-type={product.S_Pack_Type}
                                                            data-pack-qty={product.S_Pack_Qty}
                                                            onChange={handleQtyChange}
                                                            value={cell.val ? cell.val : ''}
                                                            className='qty-box'
                                                        />
                                                    </Table.Cell>
                                                ) : cell.type === 'or' ? (
                                                    <>
                                                        <Table.Cell>
                                                            <input
                                                                data-field='or'
                                                                data-item-number={index}
                                                                data-pack-type={product.S_Pack_Type}
                                                                data-pack-qty={product.S_Pack_Qty}
                                                                onChange={handleQtyChange}
                                                                onBlur={handleQtyChangeblur}
                                                                value={cell.val ? cell.val : ''}
                                                                className='qty-box'
                                                            />
                                                        </Table.Cell>
                                                    </>
                                                ) : (
                                                            <Table.Cell>
                                                                <input
                                                                    type='text'
                                                                    id={cell.type + product.Item_Number}
                                                                    data-field={cell.type}
                                                                    data-item-number={index}
                                                                    data-pack-type={product.S_Pack_Type}
                                                                    data-pack-qty={product.S_Pack_Qty}
                                                                    onChange={handleQtyChange}
                                                                    value={cell.val ? cell.val : ''}
                                                                    className='qty-box'
                                                                />
                                                            </Table.Cell>
                                                        )
                                            ) : (
                                                    <>
                                                        <Table.Cell
                                                            className={
                                                                cell.labelClass
                                                                    ? cell.labelClass
                                                                    : 'First_level_details'
                                                            }
                                                        >
                                                             
                                                            {cell.is_number ? (
                                                                utilities.changePriceToNumber(cell.val)
                                                            ) : (cell.label === "SUBSTITUTE:" ||cell.label === "REFERRAL ITEM:" ) &&
                                                                cell.val.trim() !== '-' ? (
                                                                        <Link
                                                                            to={{
                                                                                pathname: '/product/' + cell.val,
                                                                                state: {
                                                                                    title: title,
                                                                                    listType: listType
                                                                                }
                                                                            }}
                                                                        >
                                                                            {cell.val}
                                                                        </Link>
                                                                    ) : cell.val
                                                            }
                                                        </Table.Cell>
                                                    </>
                                                )}
                                        </>
                                    )}
                            </>
                        ))}

                    </Table.Row>
                </>
            ))}
        </>
    );
};