import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Loader, Grid } from "semantic-ui-react";
import { removeVendorFromFavorites, getAllFavoriteVendors } from "./actions";
import InternalServerError from "../../ErrorPages/500_error_page";
import BreadcrumbComp from "../../../components/BreadcrumbComp";
import FavVendorItem from "./VendorItem";
import utilities from "../../../utils/utilities";
import _ from 'lodash';
import search_img from '../../images/Search.svg';
import renderImages from '../../common/RenderImages';

class FavoriteVendors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      favorites: props.vendors,
      searchKey: ''
    };
    this.removeFavVendor = this.removeFavVendor.bind(this);
  }
  componentDidMount() {
    this.props.getAllFavoriteVendors();
  }
  componentWillReceiveProps(newprops) {
    if (!_.isEqual(newprops.vendors.sort(), this.state.favorites.sort())) {
      this.setState({
        favorites: newprops.vendors,
        searchKey: ''
      })
    }
  }
  removeFavVendor(vendor) {
    this.props.removeVendorFromFavorites(vendor.VendorName,vendor.VendorId);
  }
  searchItems=()=>{
    let{searchKey}=this.state;
    const favorites = this.props?.vendors?.filter(v => {
      return v.VendorName?.indexOf(searchKey.toUpperCase()) > -1;
    })
    this.setState({ favorites })
  }

  handleFieldChange = (e) => {
      this.setState({
        searchKey: e.target.value
      });
      return;
  }

  render() {
    const breadcrumbLinks = [ { link: '/home', text: 'HOME' },{ link: "/vendors", text: "VENDORS" }];
    const { favorites } = this.state;
    return (
      <div className="content-wrap">
        {this.props.isLoading && (
          <div>
            <Loader active />
          </div>
        )}
        {this.state.serverError ? (
          <InternalServerError />
        ) : (
            <>
              <div className="fav-header">
                <div className="page-header">Favorite Vendors</div>
              </div>
              <div id="page_navigation" className="fav-breadcrumb">
                <BreadcrumbComp path={breadcrumbLinks} />
              </div>
              <div className='ui icon input' id='search_div_address'>
                <span className='vender-search-header'>Search </span>
                <input
                  type='text'
                  className='search-box_address'
                  value={this.state.searchKey}
                  onChange={this.handleFieldChange}
                />
                {renderImages.getImage({
                src: search_img,
                className: 'search_icon_address cursor_p',
                onClick: this.searchItems,
                id:'search_icon'
            })}
              </div>
              <Grid className={window.innerWidth > 767 ? "very relaxed three column" : "very relaxed one column"}>
                {/* List of all favorite vendors */}
                {favorites && favorites.map(v => (
                  <div className="column">
                    <FavVendorItem
                      vendor={v}
                      removeFavVendor={this.removeFavVendor}
                    />
                  </div>
                ))}
              </Grid>
            </>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    vendors: state.FavoritesReducer.favVendors,
    isLoading: state.FavoritesReducer.fetchingFavVendors
  };
};
const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({ removeVendorFromFavorites, getAllFavoriteVendors }, dispatch)
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FavoriteVendors);
